import styled from 'styled-components';
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "@babel/polyfill";

import Layout from "../layouts";
import Head from "../components/head";
import DefaultHero from "../components/default-hero";
import CalculatorFormInsurance from "../components/calculator-form-insurance";
import CalculatorFormBonds from "../components/calculator-form-bonds";
import CalculatorFormATA from "../components/calculator-form-ata";
import SidebarForm from "../components/sidebar-form";

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page;
    const { disclaimer, calculatorType, pageAccentColor, afterContent } = page.template.templateCalculator;
    const { phoneLink } = this.props.data.wp.acfOptionsContact.themeOptionsContact;

    console.log(afterContent);
    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor || ''}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />
          <DefaultHero title={page.title} backgroundColor="#005c85" />

          <div className="container baseContainer">
            <div className="grid smallGrid">
              <div className="col-md-8 baseContainerLeft maincopy">
                <div dangerouslySetInnerHTML={{ __html: page.content }} />

                { calculatorType === 'bonds' 
                  ? <CalculatorFormBonds disclaimer={disclaimer} phoneNumber={phoneLink} />
                  : calculatorType === 'insurance'
                    ? <CalculatorFormInsurance disclaimer={disclaimer} phoneNumber={phoneLink} fields={this.props.location.state} printURL={page.wpURI} />
                    : calculatorType === 'ata'
                      ? <CalculatorFormATA disclaimer={disclaimer} phoneNumber={phoneLink} content={afterContent} printURL={page.wpURI} />
                      : null // Render null if none of the options match
                }

              </div>
              <div className="col-md-4 baseContainerRight">
                <SidebarForm />
              </div>
            </div>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  hr {
    border-color: ${({ accentColor }) => accentColor} !important;
  }
`;

export default PageTemplate;

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      uri
      wpURI
      id
      databaseId
      content
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_Calculator {
          templateCalculator {
            pageAccentColor
            calculatorType
            disclaimer
            afterContent
          }
        }
      }
    }

    wp {
      acfOptionsContact {
        themeOptionsContact {
          phoneText
          phoneLink
        }
      }
    }
  }
`;

import React from "react"
import { graphql, Link } from "gatsby"

import '../css/calculator-form.css';

import PrinterIcon from '../images/printer-icon';

class CalculatorFormInsurance extends React.Component {

  constructor(props) {
    super(props);

    if(props.fields && typeof props.fields.shipmentValue !== 'undefined')
      this.state = props.fields;
    else
      this.state = this.defaultState();

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    let message = this.validateField(name, value);

    if(value)
      value = parseFloat(value);

    if(message !== '') {
      this.setState({[name]: {value: value, hasError: true, errorMessage: message}});
    } else {
      this.setState({[name]: {value: value, hasError: false}});
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let state = this.state;
    let validSubmission = true;

    for (const elem in state) {
      let obj = state[elem];

      if(obj.isRequired !== true){
        continue;
      }

      let message = this.validateField(elem, obj.value);

      if(message !== '') {

        if(!obj.value && obj.value !== '')
          obj.value = parseFloat(obj.value)

        validSubmission = false;
        state[elem] = {value: obj.value, hasError: true, errorMessage: message};
      }
    }

    if(!validSubmission) {
      this.setState(state);
      return;
    }

    for (const elem in state) {
      let obj = state[elem];
    }

    state.insuredValue.value = (state.shipmentValue.value + state.costFreight.value) * (state.extraExpense.value / 100 + 1);

    state.totalPremium.value = (state.insuredValue.value / 100) * state.cargoRate.value;

    this.setState(state);
  }

  handleReset(event) {
    this.setState(this.defaultState());
  }

  validateField(name, value) {
    if(!value)
        return'Please enter a number';

    let intValue = parseFloat(value.toString().replace(/[^a-zA-Z0-9]/g, ''));

    if(isNaN(intValue)) {
      return 'Please enter a valid number';
    }

    return ''
  }

  checkNumber(x) {

    // check if the passed value is a number
    if(typeof x == 'number' && !isNaN(x)){

      return true;

    } else {
      return false;
    }
  }

  defaultState() {
    return {
      shipmentValue: {
        value: '',
        hasError: false,
        isRequired: true,
      },
      costFreight: {
        value:'',
        hasError: false,
        isRequired: true,
      },
      extraExpense: {
        value: 10,
        hasError: false,
        isRequired: true,
      },
      cargoRate: {
        value:'',
        hasError: false,
        isRequired: true,
      },
      insuredValue: {
        value:'0.00',
      },
      totalPremium: {
        value:'0.00',
      },

    };
  }

  currencyFormat(num) {
    if(num === '0.00')
      return num;

    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  render() {
    const { disclaimer, phoneNumber, printURL } = this.props;

    let state = this.state;


    return (
      <React.Fragment>
        <form className="calcForm" onSubmit={this.handleSubmit} onReset={this.handleReset}>
          <label htmlFor="shipmentValue">Shipment Value:</label>
          <div className="has-dollar-sign">
            <input id="shipmentValue" type="number" className={`${state.shipmentValue.hasError ? "error" : ""}`} name="shipmentValue" title="value" placeholder="Value" value={state.shipmentValue.value} onChange={this.handleChange} />
          </div>
          { state.shipmentValue.hasError && <p className="error-message">{state.shipmentValue.errorMessage}</p> }

          <label htmlFor="costFreight">Cost of Freight:</label>
          <div className="has-dollar-sign">
            <input id="costFreight" type="number" className={`${state.costFreight.hasError ? "error" : ""}`} name="costFreight" title="cost" placeholder="Cost of Freight" value={state.costFreight.value} onChange={this.handleChange} />
          </div>
          { state.costFreight.hasError && <p className="error-message">{state.costFreight.errorMessage}</p> }

          <label htmlFor="extraExpense">Extra Expense: (%)</label>
          <input id="extraExpense" type="number" className={`${state.extraExpense.hasError ? "error" : ""}`} name="extraExpense" title="extraexpense" placeholder="Extra Expense" value={state.extraExpense.value} onChange={this.handleChange} />
          { state.extraExpense.hasError && <p className="error-message">{state.extraExpense.errorMessage}</p> }
          <span className="defaultValue">(Default set at 10%)</span>


          <h3>Insured Value: <span>${this.currencyFormat(state.insuredValue.value)}</span></h3>

          <label htmlFor="cargoRate">Cargo Insurance Rate</label>
          <input id="cargoRate" type="number" className={`${state.cargoRate.hasError ? "error" : ""}`} name="cargoRate" title="cargorate" placeholder="Cargo Rate" value={state.cargoRate.value} onChange={this.handleChange} />
          { state.cargoRate.hasError && <p className="error-message">{state.cargoRate.errorMessage}</p> }
          <span className="defaultValue">(Per $100 of Value)</span>


          <h3>Total Premium: <span>${this.currencyFormat(state.totalPremium.value)}</span></h3>

          <div className="grid calculator-buttons">
            <div className="col-md-6">
              <button type="reset" onReset={this.cancelCourse}>Clear</button>
            </div>
            <div className="col-md-6">
              <button type="submit">Submit</button>
            </div>
          </div>

        </form>


        <div className="grid">
          <div className="col-sm-6">
            <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
          </div>
          { ( typeof window !== 'undefined' && state.totalPremium.value !== '0.00') &&
            <div className="col-sm-6">
               <Link to={`${printURL}print/`} state={state} className="printButton"><PrinterIcon />Print a Copy</Link>
            </div>
          }
          <div className="col-sm-12">
            <h3 className="newCustomers">New Customers Please Call: <a href={"tel:"+phoneNumber}>{phoneNumber}</a></h3>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CalculatorFormInsurance
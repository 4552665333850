import React from "react"
import { graphql, Link } from "gatsby"

import '../css/calculator-form.css';

import PrinterIcon from '../images/printer-icon';

class CalculatorFormInsurance extends React.Component {
  
  debounce(func, delay) {
    let timerId;
    return function(...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  constructor(props) {
    super(props);

    if(props.fields && typeof props.fields.shipmentValue !== 'undefined')
      this.state = props.fields;
    else
      this.state = this.defaultState();

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
    this.resetEstimate = this.resetEstimate.bind(this);
    this.handleShippingChange = this.handleShippingChange.bind(this);

    
  }

  

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    let message = this.validateField(name, value);

    if(value)
      value = parseFloat(value);

    if(message !== '') {
      this.setState({[name]: {value: value, hasError: true, errorMessage: message}});
    } else {
      this.setState({[name]: {value: value, hasError: false}});
    }

    this.debounce(this.handleSubmit, 1000)(event); 

  }
  handleOptionChange(event) {

    this.setState({
      brazil: {
        value: event.target.value
      }
    });
    this.debounce(this.handleSubmit, 1000)(event); 
  }

  handleValueChange(event){
    this.handleChange(event);

    this.debounce(this.handleSubmit, 1000)(event); 
  }
  handleShippingChange(event) {
    const selectedValue = event.target.value;
    this.setState({
      shipping: selectedValue
    });
    // this.resetEstimate();
  }
  

  handleSubmit(event) {
    event.preventDefault();

    let state = this.state;
    let validSubmission = true;

    for (const elem in state) {
      let obj = state[elem];

      if(obj.isRequired !== true){
        continue;
      }

      let message = this.validateField(elem, obj.value);

      if(message !== '') {

        if(!obj.value && obj.value !== '')
          obj.value = parseFloat(obj.value)

        validSubmission = false;
        state[elem] = {value: obj.value, hasError: true, errorMessage: message};
      }
    }

    if(!validSubmission) {
      this.setState(state);
      return;
    }

    for (const elem in state) {
      let obj = state[elem];
    }
    
    // Calculate Basic Fee
    if (state.shipmentValue.value < 10000){
      state.basicFee.value = 255.00;
    }
    else if (state.shipmentValue.value >= 10000 && state.shipmentValue.value < 50000){
      state.basicFee.value = 300.00;
    }
    else if (state.shipmentValue.value >= 50000 && state.shipmentValue.value < 150000){
      state.basicFee.value = 365.00;
    }
    else if (state.shipmentValue.value >= 150000 && state.shipmentValue.value < 400000){
      state.basicFee.value = 425.00;
    }
    else if (state.shipmentValue.value >= 400000 && state.shipmentValue.value < 1000000){
      state.basicFee.value = 480.00;
    }
    else{
      state.basicFee.value = 545.00;
    }

    // Calculate Carnet Bond
    let bondCost = (state.shipmentValue.value * .01);
    console.log("BondCost: " + bondCost);

    if (state.brazil.value === "Yes"){
      bondCost = (bondCost * .6);
    }
    else {
      bondCost = (bondCost * .4);
    }
    if (bondCost < 50){
      this.state.carnetBond.value = 50;
    }
    else{
      this.state.carnetBond.value = bondCost;
    }

    // Calculate Carnet Insurance
    
    if (state.countries.value < 4){
      state.cargoInsurance.rate = .8;
    }
    else if (state.countries.value >=4 && state.countries.value < 8 ){
      state.cargoInsurance.rate = 1.05;
    }
    else if (state.countries.value >=8 && state.countries.value < 11 ){
      state.cargoInsurance.rate = 1.55;
    }
    else {
      state.cargoInsurance.rate = 1.8;
    }

   let insuranceCost = ((state.shipmentValue.value/100) * state.cargoInsurance.rate);

   if (insuranceCost < 50){
    state.cargoInsurance.value = 50;
   }
   else{
    state.cargoInsurance.value = insuranceCost;
   }

   this.resetEstimate();

  //  state.totalEstimate.value = state.totalFee.value + state.shipping.value;
    // state.insuredValue.value = (state.shipmentValue.value + state.costFreight.value) * (state.extraExpense.value / 100 + 1);

    // state.totalPremium.value = (state.insuredValue.value / 100) * state.cargoRate.value;

    this.setState(state);
  }
  handleFinalSubmit(event){
    event.preventDefault(); // Prevent default form submission behavior

    let state = this.state;

    state.totalEstimate.value = state.basicFee.value;

    if (document.getElementById('ata-carnet-bond-checkbox').checked) {
      state.totalEstimate.value += state.carnetBond.value;
      state.carnetBond.included = true;
    }
    if (document.getElementById('lost-document-checkbox').checked) {
      state.totalEstimate.value += state.lostDoc.value;
      state.lostDoc.included = true;
    }
    if (document.getElementById('ata-carnet-cargo-insurance-checkbox').checked) {
      state.totalEstimate.value += state.cargoInsurance.value;
      state.cargoInsurance.included = true;
    }
    const shippingCost = parseFloat(this.state.shipping);

    state.totalEstimate.value += shippingCost;


    this.setState(state);
  }

  handleReset(event) {
    this.setState(this.defaultState());
  }

  validateField(name, value) {
    if(!value)
        return'Please enter a number';

    let intValue = parseFloat(value.toString().replace(/[^a-zA-Z0-9]/g, ''));

    if(isNaN(intValue)) {
      return 'Please enter a valid number';
    }

    return ''
  }

  checkNumber(x) {

    // check if the passed value is a number
    if(typeof x == 'number' && !isNaN(x)){

      return true;

    } else {
      return false;
    }
  }

  defaultState() {
    return {
      shipmentValue: {
        value: '',
        hasError: false,
        isRequired: true,
      },
      countries: {
        value: 1,
        hasError: false,
        isRequired: true,
      },
      brazil: {
        value: 'No',
        hasError: false,
        isRequired: true,
      },
      carnetBond: {
        value: 0.00,
        hasError: false,
        isRequired: true,
        included: false,
      },
      cargoRate: {
        value:'',
        hasError: false,
        isRequired: true,
      },
      basicFee: {
        value:'0.00',
      },
      totalFee: {
        value:'0.00',
      },
      totalEstimate: {
        value:'0.00',
      },
      lostDoc: {
        value: 35.00,
        included: false,
      },
      cargoInsurance: {
        value: 0.00,
        rate: 0.00,
        included:false,
      },
      shipping: "10",
    };
  }
  resetEstimate(){
    let state = this.state;
    state.totalEstimate.value = '0.00';
    this.setState(state);
  }

  currencyFormat(num) {
    if(this.checkNumber(num)){
      if(num === '0.00')
        return num;

      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    }


  render() {
    const { disclaimer, phoneNumber, printURL, content } = this.props;

    console.log(content);
    let state = this.state;


    return (
      <React.Fragment>
        <form className="calcForm" onSubmit={this.handleFinalSubmit} onReset={this.handleReset}>
          <label htmlFor="shipmentValue">Shipment Value:</label>
          <div className="has-dollar-sign">
            <input id="shipmentValue" type="number" className={`${state.shipmentValue.hasError ? "error" : ""}`} name="shipmentValue" title="value" placeholder="Value" value={state.shipmentValue.value} onChange={this.handleValueChange} />
          </div>
          { state.shipmentValue.hasError && <p className="error-message">{state.shipmentValue.errorMessage}</p> }

          <label htmlFor="countries">How Many Countries Visited?</label>
          <div className="">
            <input id="countries" type="number" className={`${state.countries.hasError ? "error" : ""}`} name="countries" title="countries" placeholder="Countries Visited" value={state.countries.value} onChange={this.handleChange} />
          </div>
          { state.countries.hasError && <p className="error-message">{state.countries.errorMessage}</p> }

          <div className="radio-buttons">
            <h5>Will the goods go to Brazil?</h5>
            <label>
              <input
                type="radio"
                value="Yes"
                checked={state.brazil.value === 'Yes'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                value="No"
                checked={state.brazil.value === 'No'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              No
            </label>
          </div>


          <h4>Basic Fee: <span>${this.currencyFormat(this.state.basicFee.value)}</span></h4>

          <div id="add-ons">
            <div id="ata-carnet-bond">
              <label htmlFor="ata-carnet-bond-checkbox">
                <input
                  type="checkbox"
                  id="ata-carnet-bond-checkbox"
                  onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                />
                Include ATA Carnet Bond for&nbsp;<span>${this.currencyFormat(state.carnetBond.value)}</span>
              </label>
            </div>
            <div id="lost-document">
              <label htmlFor="lost-document-checkbox">
                <input
                  type="checkbox"
                  id="lost-document-checkbox"
                  onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                />
                Include Lost Document Coverage for&nbsp;<span>${this.state.lostDoc.value}</span>
              </label>
            </div>
            <div id="ata-carnet-cargo-insurance">
              <label htmlFor="ata-carnet-cargo-insurance-checkbox">
                <input
                  type="checkbox"
                  id="ata-carnet-cargo-insurance-checkbox"
                  onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                />
                Include ATA Carnet Cargo Insurance for&nbsp;<span>${this.currencyFormat(state.cargoInsurance.value)}</span>
              </label>
            </div>
          </div>



          {/* <h4>Total Fee: <span>${this.currencyFormat(state.totalFee.value)}</span></h4> */}

          <div id="shipping-costs">
          <select value={this.state.shipping} onChange={this.handleShippingChange}>
            <option value="10">Standard Shipping & Handling Fee - $10</option>
            <option value="75">Expedited Shipping Fee - $75</option>
            <option value="6">Handling Only Fee (Customer Pickup) - $6</option>
            <option value="150">Same Day Pickup - $150</option>
          </select>
          </div>

          <h3>Total Estimate: <span>${this.currencyFormat(state.totalEstimate.value)}</span></h3>

          <div id="processing-costs">
            <h4>Additional Processing Costs to Consider:</h4>
            <p>Additional General List pages (per side): $12.00 per side of page.</p>
            <p>Additional sets - Counterfoils and Vouchers: $25.00 per additional set.</p>
          </div>
          <div className="grid calculator-buttons">
            <div className="col-md-6">
              <button type="reset" onReset={this.cancelCourse}>Clear</button>
            </div>
            <div className="col-md-6">
              <button type="submit">Submit</button>
            </div>
          </div>

        </form>


        <div className="grid">
          <div className="col-sm-6">
            <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
          </div>
          { ( typeof window !== 'undefined' && state.totalEstimate.value !== '0.00') &&
            <div className="col-sm-6">
               <Link to={`${printURL}print/`} state={state} className="printButton"><PrinterIcon />Print a Copy</Link>
            </div>
          }
          <div className="col-sm-12">
            <h3 className="newCustomers">New Customers Please Call: <a href={"tel:"+phoneNumber}>{phoneNumber}</a></h3>
          </div>
        </div>
        <div className="after-content-wrapper">
          <div className="after-content"dangerouslySetInnerHTML={{ __html: content }}></div>
         </div>
       
      </React.Fragment>
    )
  }
}

export default CalculatorFormInsurance
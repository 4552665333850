import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  fill: none;
  stroke: #21c6be;
  stroke-width: 1;
  stroke-miterlimit: 10;
  width: 40px;
  height: auto;
  enable-background: new 0 0 42 38.4;
  margin-right: 10px;
`;

export default function printerIcon() {
  return (
    <SVG version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 42 38.4" xmlSpace="preserve"><g><rect x="7.7" y="0.5" class="st0" width="26.7" height="12.9"/><rect x="7.7" y="24.9" class="st0" width="26.7" height="12.9"/><circle class="st0" cx="36.7" cy="17.9" r="1.6"/><circle class="st0" cx="30.7" cy="17.9" r="1.6"/><polyline class="st0" points="7.7,31.4 0.5,31.4 0.5,13.4 41.5,13.4 41.5,31.4 34.4,31.4 	"/><line class="st0" x1="13.3" y1="28.9" x2="29.1" y2="28.9"/><line class="st0" x1="13.3" y1="31.4" x2="29.1" y2="31.4"/><line class="st0" x1="13.3" y1="33.9" x2="29.1" y2="33.9"/></g></SVG>
  )
}